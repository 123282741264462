let _API = null;

export const init = (protocol, host, productType) => {
    let api = {
        API_SEARCH: `${protocol}://${host}/search/${productType}?key=`,
        API_POPULAR_SEARCHES: `${protocol}://${host}/search/${productType}/popular?limit=`,
        API_RECENT_SEARCHES: `${protocol}://${host}/search/${productType}/recent?limit=`,
        API_SEARCH_POPULAR_PRODUCTS: `${protocol}://${host}/search/popular/${productType}`,
        API_CONFIG: `${protocol}://${host}/config/${productType}`,
        API_COUNTERS: `${protocol}://${host}/counters/${productType}`,
        API_EVENT_POST: `${protocol}://${host}/data/event`,
        API_AFFILIATE_PRODUCTS: `${protocol}://${host}/aff/products`,
        API_RELEASES_ON_DAY: `${protocol}://${host}/releases/${productType}`,
        API_PATRON_LIST: `${protocol}://${host}/patrons/${productType}`,
        API_SEARCH_POPULAR_DECKS: `${protocol}://${host}/popularproducts/${productType}`,
        // ADS
        API_GET_AD_REDIRECT: `${protocol}://${host}/ad/redirect`,
        // USERS
        API_USER_SIGNUP: `${protocol}://${host}/users/signup`,
        API_USER_LOGIN: `${protocol}://${host}/users/login`,
        API_USER_FORGOTPASSWORD: `${protocol}://${host}/users/forgotpassword`,
        API_USER_CHANGEPASSWORD: `${protocol}://${host}/users/changepassword`,
        API_USER_AUTHENTICATE: `${protocol}://${host}/users/authenticate`,
        API_USER_REFRESH: `${protocol}://${host}/users/refresh`,
        // PRODUCT ALERTS
        API_ALERT_LIST: `${protocol}://${host}/alerts/${productType}`,
        API_ALERT_ADD: `${protocol}://${host}/alerts/${productType}`,
        API_ALERT_DEL: `${protocol}://${host}/alerts/${productType}/`,
        // GEO IP LOCATION API
        API_GEOIP: "https://extreme-ip-lookup.com/json?key=Qn97RtiI2gwjStzJJjuG",
        // SITE RATING
        API_SITE_RATING: `${protocol}://${host}/site/rating/${productType}`,
    };
    // persist the interpolated endpoint list
    _API = _API ? _API : api;

    return {
        endpoints: _API,
        getConfig: _apiGetConfig,
        getCounters: _apiGetCounters,
        getGeoIP: _apiGetGeoIP,
        getPatronList: _apiGetPatronList,
        searchProducts: _apiSearchProducts,
        searchProductsReleasedByDay: _apiSearchProductsReleasedByDay,
        searchProductsMostSearched: _apiSearchProductsMostSearched,
        searchProductsMostPopular: _apiSearchProductsMostPopular,
        userLogin: _apiUserLogin,
        userSignup: _apiUserSignup,
        userAuthenticate: _apiAuthenticate,
        userRefreshToken: _apiRefreshToken,
        userForgotPassword: _apiUserForgotPassword,
        userChangePassword: _apiUserChangePassword,
        getPatronList: _apiGetPatronList,
        getAlertList: _apiGetAlertList,
        deleteAlert: _apiDeleteAlert,
        addAlert: _apiAddAlert,
        putSiteRating: _apiPutSiteRating,
    };
}

const _apiGetConfig = (cb) => {
    const get_unique_regions = (sites) => {
        let regions = [];
        sites.map((site) => {
            if (regions.indexOf(site.region) == -1) {
                regions.push(site.region);
            }
        });

        regions.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        })

        return regions;
    };

    fetch(_API.API_CONFIG)
        .then(res => res.json())
        .then((data) => {
            let exchangeRates = {};
            data.exchange_rates.map(exchangeRate => {
                if (!exchangeRates[exchangeRate.base_code]) exchangeRates[exchangeRate.base_code] = {};
                exchangeRates[exchangeRate.base_code] = exchangeRate.conversion_rates
            });

            let result = {
                serverVersion: data.version,
                siteCount: data.sites.length,
                deckCount: data.product_count,
                counters: {
                    search_count: data.search_count,
                },
                notices: data.notices,
                ads: data.ads,
                carouselContent: data.carousel_content,
                sites: data.sites.map((site) => {
                    return {
                        id: site.id,
                        label: site.label,
                        url: site.url,
                        currency: site.currency,
                        region: site.region,
                        rating: site.rating,
                    }
                }).sort((a, b) => {
                    if (a.region < b.region) return -1;
                    if (a.region > b.region) return 1;

                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                }),
                regions: get_unique_regions(data.sites),
                selectedRegion: 'All',
                exchangeRates: exchangeRates,
            };

            cb ? cb(result) : null;
        })
        .catch((e) => {
            console.error("Failed to fetch site configuration");
            console.error(e);
        })
}

function _apiGetCounters(cb) {
    fetch(_API.API_COUNTERS)
        .then(res => res.json())
        .then((data) => {
            let result = {
                counters: {
                    search_count: data.search_count,
                }
            };
            cb ? cb(result) : null;
        })
        .catch(e => {
            console.error(e);
        })
}

function _apiGetGeoIP(cb) {
    fetch(_API.API_GEOIP)
        .then(res => res.json())
        .then((data) => {
            let targetCurrency = 'USD';
            switch (data.continent) {
                case 'Europe':
                    if (data.countryCode == 'GB' || data.countryCode == 'GI') {
                        targetCurrency = 'GBP';
                    } else {
                        targetCurrency = 'EUR';
                    }
                    break;
                case 'Oceania':
                    if (data.countryCode == 'AU') {
                        targetCurrency = 'AUD';
                    }
                    break;
                case 'North America':
                    if (data.countryCode == 'CA') {
                        targetCurrency = 'CAD';
                    }
                    break;
            }

            let result = {
                countryCode: data.countryCode,
                continent: data.continent,
                region: data.region,
            };

            if (cb) {
                cb(result);
            }
        }).catch(e => {
            console.error(e);
        })
}

function _apiGetPatronList(cb) {
    fetch(_API.API_PATRON_LIST)
        .then(res => res.json())
        .then((data) => {
            cb ? cb(data) : null
        }).catch(e => {
            console.error(e);
        });
}

function _apiSearchProducts(searchText, countryCode, limit, page, filters, cb) {
    let filter = JSON.stringify(filters);

    searchText = searchText.trim();
    if (searchText.length >= 2) {
        fetch(`${_API.API_SEARCH}${searchText}&country_code=${countryCode}&limit=${limit}&page=${page}&filters=${filter}`)
            .then(res => {
                if (!res.ok) throw res.status;
                return res.json()
            })
            .then((data) => {
                let maxResults = data.max_results;
                let searchStats = null;

                if (maxResults > 0) {
                    searchStats = data.search_stats;
                }

                data = data.products.map((d) => {
                    d.price_usd = d.site.indexOf('kickstarter.com') == -1
                        ? d.price_usd
                        : parseInt(d.price)
                    return d;
                }).sort((a, b) => {
                    if (a.site.indexOf('kickstarter.com') != -1
                        && b.site.indexOf('kickstarter.com') != -1) {
                        return a.price_usd - b.price_usd
                    }
                    return 0
                })

                cb ? cb({
                    maxResults: maxResults,
                    searchStats,
                    searchPage: page,
                    resultText: searchText,
                    results: data.filter(deck => deck.available === true),
                    isLoading: false,
                }) : null;
            })
            .catch((e) => {
                cb ? cb({
                    error: e,
                    isLoading: false,
                    searchStats: null,
                }) : null;
            })
    }
}

function _apiSearchProductsReleasedByDay(when, countryCode, limit, page, filters, cb) {
    const MS_PER_DAY = 86400000;
    let day;
    switch (when) {
        case "today":
            day = Date.now();
            break;
        case "yesterday":
            day = Date.now() - MS_PER_DAY;
    }
    let filter = JSON.stringify(filters);

    let url = `${_API.API_RELEASES_ON_DAY}/${day - (day % MS_PER_DAY)}?country_code=${countryCode}&limit=${limit}&page=${page}&filters=${filter}`;
    fetch(url)
        .then(res => res.json())
        .then((data) => {
            let maxResults = data.max_results;
            let searchStats = null;

            if (maxResults > 0) {
                searchStats = data.search_stats;
            }

            data = data.products.map((d) => {
                d.price_usd = d.site.indexOf('kickstarter.com') == -1
                    ? d.price_usd
                    : parseInt(d.price)
                return d;
            }).sort((a, b) => {
                if (a.site.indexOf('kickstarter.com') != -1
                    && b.site.indexOf('kickstarter.com') != -1) {
                    return a.price_usd - b.price_usd
                }
                return 0
            })

            cb ? cb({
                maxResults: maxResults,
                searchStats,
                searchPage: page,
                resultText: `${when}'s releases`,
                results: data.filter(deck => deck.available === true),
                isLoading: false,
            }) : null;
        })
        .catch((e) => {
            cb ? cb({
                error: e,
                isLoading: false,
                searchStats: null,
            }) : null;
        })
}

function _apiSearchProductsMostSearched(countryCode, filters, cb) {
    console.log(filters);
    fetch(`${_API.API_SEARCH_POPULAR_PRODUCTS}?country_code=${countryCode}&filters=${JSON.stringify(filters)}`)
        .then(res => res.json())
        .then((data) => {
            let maxResults = data.max_results;
            let searchStats = null;

            if (maxResults > 0) {
                searchStats = data.search_stats;
            }

            data = data.products.map((d) => {
                d.price_usd = d.site.indexOf('kickstarter.com') == -1
                    ? d.price_usd
                    : parseInt(d.price)
                return d;
            }).sort((a, b) => {
                if (a.site.indexOf('kickstarter.com') != -1
                    && b.site.indexOf('kickstarter.com') != -1) {
                    return a.price_usd - b.price_usd
                }
                return 0
            })

            cb ? cb({
                maxResults: maxResults,
                searchStats,
                searchPage: 0,
                resultText: `Top ${data.length} Popular Products`,
                results: data.filter(deck => deck.available === true),
                isLoading: false,
            }) : null;
        }).catch(e => {
            console.error(e);
        });

}

function _apiSearchProductsMostPopular(countryCode, filters, cb) {
    let filter = JSON.stringify(filters);

    fetch(`${_API.API_SEARCH_POPULAR_DECKS}?country_code=${countryCode}&filters=${filter}`)
        .then(res => res.json())
        .then((data) => {
            let decks = data.map(d => {
                d.relevance = 0;
                return d
            }).sort((a, b) => a.price_usd - b.price_usd)
                .filter((d) => {
                    return d.currency != 'Days' && d.price_usd != 0.0
                });

            let results = {
                isLoading: false,
                searchStats: null,
                maxResults: decks.length,
                results: decks.filter((d) => d.available === true),
                resultText: `Most popular`,
            };
            cb ? cb(results) : null;
        })
        .catch((e) => {
            console.error(e);
        })

}

function _apiUserLogin(email, password, cb) {
    // send the login request
    fetch(_API.API_USER_LOGIN, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: email,
            password
        })
    }).then(res => res.json())
        .then(resp => {
            let result = {
                type: "success",
                username: resp.username,
                access_token: resp.access_token,
                refresh_token: resp.refresh_token,
            };
            cb ? cb(result) : null;
        }).catch(e => {
            let result = {
                type: "error",
                message: "Login failed. Try again later",
            }
            cb ? cb(result) : null;
        })

}

function _apiUserSignup(email, password, commsConsent, cb) {
    let data = {
        username: email,
        password: password,
        comms_consent: commsConsent
    };

    fetch(_API.API_USER_SIGNUP, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    }).then(resp => {
        // handle the response
        switch (resp.status) {
            case 200: {
                _apiUserLogin(data.username, data.password, (result) => {
                    cb ? cb(result) : null;
                });
                break;
            }

            default: {
                switch (resp.status) {
                    case 400: {
                        cb ? cb({
                            type: "error",
                            message: "Your password needs to be at least 8 characters long, have both upper and lower case letters as well as one or more numbers",
                        }) : null
                    }
                        break;

                    default: {
                        cb ? cb({
                            type: "error",
                            message: `An error occurred while creating your account. Please try again later (${resp.status})`,
                        }) : null
                    }
                        break;
                }
            }
        }
    }).catch(e => {
        console.error(e);
    })
}

function _apiUserForgotPassword(email, cb) {
    fetch(_API.API_USER_FORGOTPASSWORD, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: email,
        })
    })
        .then(resp => {
            // do nothing yet
            cb ? cb({ type: "success" }) : null
        }).catch(e => {
            cb ? cb({ type: "error", message: "Failed to reset password. Try again later" }) : null
            // alert("Failed to reset password. Try again later")
        })
}

function _apiUserChangePassword(email, newPassword, token, cb) {
    fetch(_API.API_USER_CHANGEPASSWORD, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: email,
            password: newPassword,
            token: token,
        })
    })
        .then(resp => {
            // do nothing yet
            if (resp.status == 200) {
                cb ? cb({
                    type: "success"
                }) : null;
                //   alert("Your password has been changed. You may now login using your new password")
            } else {
                cb ? cb({
                    type: "error",
                    message: "An error occurred while changing your password. Please try again later"
                }) : null;
                //   alert("An error occurred while changing your password. Please try again later");
            }
            // this.setState({showLoginForm: false})
        }).catch(e => {
            cb ? cb({
                type: "error",
                message: "Failed to reset password. Try again later",
            }) : null;
            // alert("Failed to reset password. Try again later")
        })
}

function _apiAuthenticate(username, access_token, refresh_token, cb) {
    let data = {
        username,
        token: access_token,
    };

    fetch(_API.API_USER_AUTHENTICATE, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    }).then(res => {
        if (res.ok) {
            return res.json()
        } else {
            if (res.status == 401 || res.status == 403) {
                if (refresh_token) {
                    _apiRefreshToken(username, refresh_token, cb);
                } else {
                    cb ? cb({
                        type: "refresh_needed",
                        message: "Access Token has expired and refresh token unavailable. Login required"
                    }) : null;
                }
            }
        }
    }).then((data) => {
        if (data) {
            let result = {
                type: "success",
                username: data.username,
                access_token: data.access_token,
                refresh_token: data.refresh_token,
            };
            cb ? cb(result) : null;
        }
    })
        .catch((e) => {
            console.error(e)
            cb ? cb({
                type: "error",
                message: "Failed to authenticate user. Try logging in again"
            }) : null;
        })
}

function _apiRefreshToken(username, refresh_token, cb) {
    let data = {
        username,
        token: refresh_token,
    };

    fetch(_API.API_USER_REFRESH, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    }).then(res => {
        if (res.ok) {
            return res.json();
        } else {
            cb ? cb({
                type: "refresh_token_failed",
                message: "Refresh of access token failed"
            }) : null;
        }
    }).then((data) => {
        if (data) {
            let result = {
                type: "success",
                username: data.username,
                access_token: data.access_token,
                refresh_token: data.refresh_token,
            };
            cb ? cb(result) : null;
        }
    }).catch(e => {
        console.error(e)
        cb ? cb({
            type: "server_error",
            message: "Refresh of access token failed"
        }) : null;
    })
}

function _apiGetAlertList(userAccessToken, cb) {
    fetch(_API.API_ALERT_LIST, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'bearer': userAccessToken,
        }
    }).then(res => res.json())
        .then(data => {
            cb ? cb({
                items: data,
            }) : null;
        }).catch(e => {
            console.error(e)
        })
}

function _apiDeleteAlert(alertId, userAccessToken, cb) {
    fetch(`${_API.API_ALERT_DEL}${alertId}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'bearer': userAccessToken,
        },
        body: JSON.stringify({ alertId })
    }).then(res => res.json())
        .then(data => {
            cb ? cb(data) : null;
        }).catch(e => {
            console.error(e)
        })
}

function _apiAddAlert(alertText, userAccessToken, cb) {
    fetch(_API.API_ALERT_ADD, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'bearer': userAccessToken,
        },
        body: JSON.stringify({ alert_text: alertText })
    }).then(res => res.json())
        .then(data => {
            cb ? cb(data) : null;
        }).catch(e => {
            console.error(e)
        })

}

function _apiPutSiteRating(site_id, rating, userAccessToken, cb) {
    fetch(_API.API_SITE_RATING, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'bearer': userAccessToken,
        },
        body: JSON.stringify({ site_id: site_id, rating: rating })
    })
    .then(resp => resp.json())
    .then(resp => {
        cb ? cb({
            type: "success",
            new_rating: resp.rating
        }) : null;
    }).catch(e => {
        console.error(e)
    })
}