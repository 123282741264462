import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Select, MenuItem, Grid, Card, Paper, CardMedia, Typography } from '@material-ui/core';
import { currencyCodeToSymbol, formatNumber } from '../utils';
import PriceScale from './PriceScale';
import CurrencySelector from './CurrencySelector';
import RegionSelector from './RegionSelector';
import PriceSelector from './PriceSelector';
import SiteRating from './SiteRating';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        flexGrow: 1,
        marginBottom: 20,
        backgroundColor: theme.palette.content.backgroundColor,
    },
    container: {
        flexGrow: 1,
        marginBottom: theme.spacing(1),
    },
    label: {
        fontWeight: 800,
        fontSize: 16,
        marginBottom: 15,
    },
    filter: {
        paddingRight: 5,
        paddingLeft: 5,
        marginBottom: 20,
    },
    priceScale: {
    },
    pagination: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    spinner: {},
    hidden: {
        display: 'none',
    }
}));

export default function ResultsArea(props) {
    let classes = useStyles();
    let searchResults = props.searchResults;

    const convertUSDPriceToTargetCurrency = (price) => {
        if (props.exchangeRates) {
            let rates = props.exchangeRates[targetCurrency];
            if (rates) {
                return (price * (1 / rates['USD'])).toFixed(2);
            }
        } else {
            return price.toFixed(2)
        }
    }

    const convertTargetCurrencyPriceToUSD = (price) => {
        if (props.exchangeRates) {
            let rates = props.exchangeRates['USD'];
            if (rates) {
                return (price * (1 / rates[targetCurrency])).toFixed(2)
            }
        } else {
            return price.toFixed(2);
        }
    }

    const siteFromURL = (url) => {
        if (props.sites) {
            return props.sites.find((site) => {
                return site.url == url;
            });
        } else {
            return null;
        }
    }

    // const isItemInRegion = (item) => {
    //     return true;
    //     // if (props.sites) {
    //     //     return props.sites.find((site) => {
    //     //         return props.region == 'All' || (site.url == item.product.site && site.region == props.region)
    //     //     })
    //     // } else {
    //     //     return true
    //     // }
    // };

    const getSessionCurrency = () => sessionStorage.getItem("targetCurrency");
    const [targetCurrency, setTargetCurrency] = React.useState(getSessionCurrency() || 'USD');
    const onTargetCurrencyChange = (e) => {
        setTargetCurrency(e.target.value);
        sessionStorage.setItem("targetCurrency", e.target.value);
    }

    const currencyFromGeoData = (geoIP) => {
        let currency = targetCurrency;
        if (geoIP) {
            switch (geoIP.continent) {
                case 'Europe':
                    if (geoIP.countryCode == 'GB' || geoIP.countryCode == 'GI') {
                        currency = 'GBP';
                    } else {
                        currency = 'EUR';
                    }
                    break;
                case 'Oceania':
                    if (geoIP.countryCode == 'AU') {
                        currency = 'AUD';
                    }
                    break;
                case 'North America':
                    if (geoIP.countryCode == 'CA') {
                        currency = 'CAD';
                    }
                    break;
            }
        }
        return currency;
    }

    useEffect(() => {
        if (!getSessionCurrency()) {
            let currency = currencyFromGeoData(props.geoIP);
            setTargetCurrency(currency)
        }
    }, [props.geoIP])

    // const [region, setRegion] = React.useState(props.storage.loadSetting('region', 'All'));
    const onRegionChange = (e) => {
        // setRegion(e.target.value);
        // props.storage.saveSetting('region', e.target.value);
        if (props.onRegionChange) {
            props.onRegionChange(e.target.value);
        }
    }

    const onPriceChange = (from, to) => {
        if (props.onPriceRangeChange) {
            let fromPrice = parseInt(from);
            let toPrice = parseInt(to);

            props.onPriceRangeChange(
                convertTargetCurrencyPriceToUSD(isNaN(fromPrice) ? null : fromPrice), 
                convertTargetCurrencyPriceToUSD(isNaN(toPrice) ? null : toPrice)
            );
        }
    }

    if (searchResults) {
        let searchStats = searchResults.searchStats;
        let label;

        if (searchResults) {
            if (searchResults.maxResults > 0) {
                label = (
                    <div className={classes.label}>
                        {`${searchResults.maxResults} results found for "${searchResults.resultText}"`}<br />
                        {searchResults.searchPage ? `Showing page ${searchResults.searchPage + 1} of ${Math.trunc(searchResults.maxResults / props.resultsPerPage) + 1}` : null}
                    </div>
                )
            } else {
                label = <div className={classes.label}>
                    {`No results found for "${searchResults.resultText}"`}
                </div>
            }
        }

        return (
            <Grid container className={classes.root} >
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item className={classes.filter}>
                        <CurrencySelector
                            currencies={props.currencies}
                            onCurrencyChange={onTargetCurrencyChange}
                            targetCurrency={targetCurrency}
                        />
                    </Grid>
                    <Grid item className={classes.filter}>
                        <RegionSelector
                            regions={props.regions}
                            currentRegion={props.region}
                            onRegionChange={onRegionChange}
                        />
                    </Grid>
                    <Grid item className={classes.filter}>
                        <PriceSelector 
                            targetCurrency={targetCurrency}
                            onPriceChange={onPriceChange} 
                        />
                    </Grid>

                </Grid>
                {searchResults.resultText.length > 0 ?
                    <Grid item xs={12}>{label}</Grid> : null}
                {searchResults.maxResults && searchStats ?
                    <Grid className={classes.priceScale} item xs={12}>
                        <PriceScale
                            title="Price Range:"
                            currency={currencyCodeToSymbol(targetCurrency)}
                            min={convertUSDPriceToTargetCurrency(searchStats.min_price)}
                            avg={convertUSDPriceToTargetCurrency(searchStats.avg_price)}
                            max={convertUSDPriceToTargetCurrency(searchStats.max_price)}
                        />
                    </Grid> : null
                }

                {/* Pagination control */}
                <Grid className={classes.pagination} item xs={12}>
                    <Pagination
                        totalResults={searchResults.maxResults}
                        resultsPerPage={searchResults.searchPage !== undefined ? props.resultsPerPage : Number.MAX_SAFE_INTEGER}
                        currentPage={searchResults.searchPage}
                        onPageChange={props.onPageChange}
                    />
                </Grid>

                {/* The results list */}
                <Grid container justifyContent="center" className={classes.container} spacing={2}>
                    {searchResults.results
                        // .filter(r => isItemInRegion(r))
                        .map(r => {
                            if (r.site.indexOf('kickstarter.com') == -1) {
                                r.currency = targetCurrency;
                                r.price = convertUSDPriceToTargetCurrency(r.price_usd);
                            } else {
                                r.price = r.price_usd;
                            }
                            let site = siteFromURL(r.site);
                            if (site) {
                                r.site_label = site.label;
                                r.site_region = site.region;
                                r.site_rating = site.rating;
                            }

                            return (
                                <ResultItem
                                    key={`${r.site}_${r.name}`}
                                    result={r}
                                    onLinkClick={(item) => props.onLinkClick(searchResults.resultText, item)}
                                    minPrice={searchStats ? searchStats.min_price : 0}
                                    avgPrice={searchStats ? searchStats.avg_price : 0}
                                    maxPrice={searchStats ? searchStats.max_price : 0}
                                />
                            )
                        })}
                </Grid>

                {/* Pagination control */}
                <Grid className={classes.pagingation} item xs={12}>
                    <Pagination
                        totalResults={searchResults.maxResults}
                        resultsPerPage={props.resultsPerPage}
                        currentPage={searchResults.searchPage}
                        onPageChange={props.onPageChange}
                    />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <div className={classes.root}>
                <LoadingAnimation />
            </div>
        )
    }
}

const CARDHEIGHT = 350;

const useCardStyles = makeStyles((theme) => ({
    root: {
        minWidth: 190,
        maxWidth: 190,
        minHeight: CARDHEIGHT,
        borderRadius: 20,
        boxShadow: '2px 2px 8px #000',
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            minWidth: 140,
            maxWidth: 140,
        }
    },
    media: {
        height: CARDHEIGHT,
        backgroundSize: 'cover',
        transform: 'scale(1.0)',
        transition: 'transform 0.5s',
        transitionTimingFunction: 'ease',
        '&:hover': {
            transition: 'transform 0.5s',
            transitionTimingFunction: 'ease',
            transform: 'scale(1.03)',
        },
    },
    title: {
        display: 'block',
        fontSize: 12,
        fontWeight: 600,
        height: 42,
        minHeight: 50,
        maxHeight: 50,
        color: "white",
        overflow: 'hidden',
    },
    price: {
        marginTop: 5,
        textShadow: '1px 1px #000',
        fontSize: 12,
        paddingTop: 2,
        paddingBottom: 2,
        width: '60%',
        fontWeight: 800,
        borderRadius: 10,
        marginBottom: theme.spacing(1),
        boxShadow: '2px 2px #000',
    },
    labelSite: {
        fontSize: 12,
        color: 'white',
    },
    labelRegion: {
        fontSize: 12,
        fontStyle: 'italic',
        color: 'white',
    },
    price_low: {
        backgroundColor: '#519D30',
        color: "white",
    },
    price_avg: {
        backgroundColor: '#e2992b',
        color: 'white',
    },
    price_high: {
        backgroundColor: '#cc2020',
        color: 'white',
    },
    overlay: {
        position: 'relative',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        top: 180,
        height: CARDHEIGHT - 180,
        padding: 5,
        borderRadius: 0,
    },
    premium_overlay: {
        top: 156,
    },
    itemLink: {
        textDecoration: 'none',
    },
}));

function ResultItem(props) {
    const classes = useCardStyles();
    let item = props.result;
    let priceIndicator = classes.price_low;
    let isKickstarterItem = item.site.indexOf('kickstarter.com') != -1;

    if (!isKickstarterItem) {
        if (item.price_usd < props.minPrice + (props.avgPrice - props.minPrice) / 2) {
            priceIndicator = classes.price_low;
        } else if (item.price_usd < props.avgPrice + (props.maxPrice - props.avgPrice) / 2) {
            priceIndicator = classes.price_avg;
        } else {
            priceIndicator = classes.price_high;
        }
    } else {
        if (item.price < 2) {
            priceIndicator = classes.price_high;
        } else if (item.price < 7) {
            priceIndicator = classes.price_avg;
        } else {
            priceIndicator = classes.price_low;
        }
    }

    let priceClass = clsx(classes.price, priceIndicator);

    return (
        <Grid item key={`${item.site}_${item.name}`}>
            <a className={classes.itemLink} href={item.url} target="_blank" onClick={() => props.onLinkClick(item)}>
                <Card className={classes.root}>
                    <CardMedia
                        className={classes.media}
                        image={item.image_url}
                        title={item.name}
                    >

                        {item.premium_result
                            ? <Ribbon title="premium" />
                            : null
                        }

                        <Paper className={clsx(classes.overlay, item.premium_result && classes.premium_overlay)}>
                            <Typography
                                className={classes.title}
                            >
                                {item.name}
                            </Typography>

                            <center>
                                <Typography
                                    className={priceClass}
                                >
                                    {isKickstarterItem
                                        ? `${item.price < 1 ? "< 1" : item.price} ${item.price <= 1 ? 'Day' : 'Days'} left`
                                        : `${currencyCodeToSymbol(item.currency)} ${item.price}`}
                                </Typography>
                            </center>

                            <Typography
                                className={classes.labelSite}
                            >
                                {item.site_label}
                            </Typography>

                            <SiteRating 
                                className={classes.rating} 
                                rating={item.site_rating} 
                                title={`User rating for ${item.site_label}: ${item.site_rating ? item.site_rating.toFixed(1) : "None yet"}\n\nTo add your rating for a site, go to the Supported Sites section from the menu or the footer`}
                            />

                            <Typography
                                className={classes.labelRegion}
                            >
                                {item.site_region}
                            </Typography>

                        </Paper>
                    </CardMedia>

                </Card>
            </a>

        </Grid>
    )
}

const useRibbonStyles = makeStyles((theme) => ({
    ribbon: {
        position: "relative",
        backgroundColor: 'rgba(235, 235, 0, 0.85)',
        color: 'black',
        fontWeight: 600,
        fontSize: 10,
        width: 150,
        boxShadow: '-8px 4px 5px 0px #606060',
        zIndex: 3,
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: 5,
        font: 'Lato',
        '&::before': {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        '&::after': {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        transform: 'rotate(45deg)',
        top: 15,
        left: 90,
        [theme.breakpoints.down('sm')]: {
            top: 10,
            left: 42,
        }
    },
}));

function Ribbon(props) {
    let classes = useRibbonStyles();

    return (
        <React.Fragment>

            <div className={classes.ribbon}><span>{props.title}</span></div>
        </React.Fragment>
    )
}

const usePaginationStyles = makeStyles((theme) => ({
    root: {},
    pageLink: {
        display: 'inline-block',
        minWidth: 40,
        minHeight: 40,
        marginRight: theme.spacing(1),
        borderRadius: 5,
        border: '1px solid grey',
        color: theme.palette.primary.main,
        padding: theme.spacing(1),
        fontSize: 16,
        fontWeight: 800,
        textDecoration: 'none',
        color: '#eeeeee',
        borderWidth: 2,
        borderColor: 'black',
        background: 'rgb(128,196,128)',

        // background: 'rgb(224,224,224)',
        // background: 'linear-gradient(135deg, rgba(224,224,224,1) 0%, rgba(198,198,198,1) 50%, rgba(221,221,221,1) 100%)',
        background: 'rgb(64,64,64)',
        // background: 'linear-gradient(135deg, rgba(64,64,64,1) 0%, rgba(128,128,128,1) 50%, rgba(64,64,64,1) 100%)',
        '&:visited': {
            color: '#eeeeee',
        },
        '&:hover': {
            // color: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: 2,
            minWidth: 20,
            minHeight: 20,
            fontSize: 12,
        },
    },
    currentPage: {
        fontWeight: 800,
        color: 'black',
        borderStyle: 'solid',
        borderWidth: 3,
        borderColor: 'rgb(64,96,64)',
        background: 'rgb(128,196,128)',
        // background: 'linear-gradient(135deg, rgba(64,96,64,1) 0%, rgba(128,196,128,1) 50%, rgba(64,96,64,1) 100%)',
        // background: 'rgb(64,64,64)',
        // background: 'linear-gradient(135deg, rgba(64,64,64,1) 0%, rgba(128,128,128,1) 50%, rgba(64,64,64,1) 100%)',
    },
    transparent: {
        opacity: 0,
    }
}));

function Pagination(props) {
    let classes = usePaginationStyles();

    const onPageClick = (e) => {
        let targetPage;
        e.preventDefault();
        switch (e.target.text.toLowerCase()) {
            case "prev":
                targetPage = props.currentPage - 1;
                break;
            case "next":
                targetPage = props.currentPage + 1;
                break;
            default:
                targetPage = parseInt(e.target.text) - 1;
                break;
        }
        props.onPageChange(targetPage);
    }

    const onSelectChange = (e) => {
        e.preventDefault();
        let targetPage = e.target.value;
        props.onPageChange(targetPage);
    }

    const renderPageLinks = () => {
        let pageCount = Math.ceil(props.totalResults / props.resultsPerPage);
        let items = [];

        if (pageCount > 1) {
            const PIVOT = 5;

            let midPoint = Math.max(props.currentPage - PIVOT, 0) + PIVOT;
            let end = Math.min(pageCount, midPoint + 5);

            let start = Math.max(end - 10, 0);

            if (props.currentPage > 0) {
                items.push(<a key="pg_prev" className={classes.pageLink} href="" onClick={onPageClick}>Prev</a>)
            } else {
                items.push(<span key="pg_prev" className={clsx(classes.pageLink, classes.transparent)}>&nbsp;&nbsp;&nbsp;&nbsp;</span>)
            }

            for (let i = start; i < end; i++) {
                items.push(i != props.currentPage
                    ? <a key={`pg_${i}`} className={classes.pageLink} href="" onClick={onPageClick}>{i + 1}</a>
                    : <span key={`pg_${i}`} className={clsx(classes.pageLink, classes.currentPage)}>{i + 1}</span>
                );
            }

            if (props.currentPage < pageCount - 1) {
                items.push(<a key="pg_next" className={classes.pageLink} href="" onClick={onPageClick}>Next</a>)
            }

            if (start > 0 || end < pageCount) {
                let pageItems = [];
                for (let i = 0; i < pageCount; i++) {
                    pageItems.push(
                        <MenuItem
                            key={`dd_${i}`}
                            value={i}
                        >{i+1}</MenuItem>
                    )
                }
                items.push(
                    <Select 
                        key="selector"
                        id="page_dropdown" 
                        value={props.currentPage} 
                        onChange={onSelectChange}
                    >
                        {pageItems}
                    </Select>
                )
            }

        }

        return items;
    }

    return (
        <React.Fragment>
            <span>{renderPageLinks()}</span>
        </React.Fragment>
    )
}

const useAnimationStyles = makeStyles((theme) => ({
    lds_ellipsis: {
        display: "inline-block",
        position: "relative",
        width: 80,
        height: 80,
        "& div": {
            position: "absolute",
            top: 33,
            width: 13,
            height: 13,
            borderRadius: "50%",
            background: "orange",
            animationTimingFunction: "cubic-bezier(0, 1, 1, 0)",
        },
        "& div:nth-child(1)": {
            left: 8,
            animation: "$lds_ellipsis1 0.6s infinite",
        },
        "& div:nth-child(2)": {
            left: 8,
            animation: "$lds_ellipsis2 0.6s infinite",
        },
        "& div:nth-child(3)": {
            left: 32,
            animation: "$lds_ellipsis2 0.6s infinite",
        },
        "& div:nth-child(4)": {
            left: 56,
            animation: "$lds_ellipsis3 0.6s infinite",
        },
    },
    "@keyframes lds_ellipsis1": {
        "0%": {
            transform: "scale(0)",
        },
        "100%": {
            transform: "scale(1)",
        },
    },
    "@keyframes lds_ellipsis3": {
        "0%": {
            transform: "scale(1)",
        },
        "100%": {
            transform: "scale(0)",
        },
    },
    "@keyframes lds_ellipsis2": {
        "0%": {
            transform: 'translate(0, 0)',
        },
        "100%": {
            transform: 'translate(24px, 0)',
        },
    }

}));

function LoadingAnimation(props) {
    let classes = useAnimationStyles();

    return (
        <div className={classes.lds_ellipsis}><div></div><div></div><div></div><div></div></div>
    )
}