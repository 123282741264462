import clsx from 'clsx';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useRatingStyles = makeStyles((theme) => ({
    starpanel: {
        display: "flex",
        flexDirection: "row",
        padding: "5px",
        cursor: "pointer",
        justifyContent: "center"
    },
    rating: {
        color: "yellow",
        fontSize: 12,
        minWidth: "100%",
        borderRadius: "10px",
    },
    hoverRating: {
        color: "orange",
        fontSize: 12,
        minWidth: "100%",
        borderRadius: "10px",
    },
    background: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    }
}));

export default function SiteRating(props) {
    let [mouseHover, setMouseHover] = useState(false);
    let [myRating, setMyRating] = useState(0);
    let styles = useRatingStyles();
    let rating = Math.floor(props.rating) || 0;

    const renderStars = (count) => {
        let stars = [];
        
        if (mouseHover && myRating > 0) {
            count = Math.min(5, myRating);
        }
        for (let i = 0; i < count; i++) {
            stars.push(<div key={`star_${i}`} value={i+1}>★</div>)
        }
        for (let i = count; i < 5; i++) {
            stars.push(<div key={`star_${i}`}value={i+1}>☆</div>)
        }
        return (
            <>
                {stars}
            </>
        ) 
    }

    const onRatingMouseEnter = (e) => {
        if (props.interactive) {
            setMouseHover(true)
        }
    }

    const onRatingMouseLeave = (e) => {
        if (props.interactive) {
            setMouseHover(false)
        }
    }

    const onRatingMouseMove = (e) => {
        if (props.interactive && mouseHover) {
            let ratingValue = e.target.getAttribute("value");
            setMyRating(ratingValue)
        }
    }

    const onRatingClick = (e) => {
        if (props.interactive && mouseHover && props.onRatingSubmit) {
            setMouseHover(false);
            props.onRatingSubmit(props.site, parseInt(myRating))
        }
    }

    let style = props.withBackground ? 
        clsx(mouseHover ? 
            styles.hoverRating : 
            styles.rating, styles.starpanel, styles.background
        ) : 
        clsx(styles.starpanel, styles.rating);
        
    return (
        <div 
            className={style} 
            title={props.title} 
            onMouseEnter={onRatingMouseEnter}
            onMouseLeave={onRatingMouseLeave}
            onMouseMove={onRatingMouseMove}
            onClick={onRatingClick}
        >
            {renderStars(rating)}
        </div>
    )
}
