import { red, grey, blue, pink, green, blueGrey } from '@material-ui/core/colors';
import { createTheme, withTheme } from '@material-ui/core/styles';

// A custom theme for this app
export const lightTheme = () => {
  return createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#3a039c",
      },
      secondary: {
        main: pink.A400,
      },
      error: {
        main: "#d80b73",
      },
      appBar: {
        backgroundColor: "#320263",
      },
      content: {
        backgroundColor: "#faf6ff",
      },
      footer: {
        backgroundColor: "#630263",
        color: 'white',
      }

      // background: {
      //   default: grey.A100,
      // }
    },
  })
}

export const darkTheme = () => {
  return createTheme({
    palette: {
      type: "dark",
      // primary: {
      //   main: red.A400,
      // },
      // secondary: {
      //   main: green.A400,
      // },
      // error: {
      //   main: red.A400,
      // },
      appBar: {
        backgroundColor: "#212121",
      },
      content: {
        backgroundColor: "#303030",
      },
      footer: {
        backgroundColor: "#212121",
        color: 'white',
      }
    },

  })
}
